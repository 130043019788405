import React from 'react';
import './Imagebox.css';

function Imagebox({img}) {
    return (
        <div className='imagebox--container' style={{ backgroundImage : `url(${img})` }}>
        </div>
    )
}

export default Imagebox;