import React from 'react';

function SixSection({onClick}) {
    return (
        <div className='home--third--second--section'>
            <div className='home--third--second--first'>
            Waitlist.
            </div>
            <div className='home--third--second--second'>
            Want to have first access to the app?
            </div>
            <div className='home--third--second--third' onClick={onClick}>
                <form>
                    <input type="text" placeholder='email@provider.com'/>
                </form>
            </div>
            <div className='home--third--second--forth'>
            By signing up for the waitlist, you agree to additional communication from Skip the Chit Chat.
            </div>
        </div>
    );
}

export default SixSection;