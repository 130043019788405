import React from 'react';
import Status from '../../components/Status';
import Imagebox from '../../components/Imagebox';
import volleyball from '../../images/volleyball.jpg';
import food from '../../images/food.jpg';

function SecondSection() {
    return (
        <div className='home--first-example-wrapper'>
            <div className='home--first--example--outerbox'>
            </div>
            <div className='home--first--example--innerbox'>
            </div>
            <div className='home--first--example--status'>
                <Status statusTitle={"Foodie Walk with Harry"} statusStatus={"1 hr • West End • 0/1"}/>
            </div>
            <div className='home--second--example--status' >
                <Status statusTitle={"Volleyball with Tracy"} statusStatus={"3 hr • Downtown • 4/5"}/>
            </div>
            <div className='home--imagebox--one'>
                <Imagebox img={volleyball}/>
            </div>
            <div className='home--imagebox--two'>
                <Imagebox img={food}/>
            </div>
        </div>
    )
}

export default SecondSection;