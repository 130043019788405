import React from 'react';
import './Map.css';
import VancouverMap from '../images/VancouverMap.png';


function MyMap() {
    return (
        <div className='map--container' style={{ backgroundImage : `url(${VancouverMap})` }}>
        </div>
    );
}

export default MyMap;