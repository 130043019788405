import React, { useState, useEffect, useCallback } from 'react';
import './FirstSection.css';
import homeBackground from '../../images/Home.svg';
import ornament from '../../images/Ornament.svg';
import GoogleStore from '../../images/Google Play.png';
import AppleStore from '../../images/AppStore.png';

function FirstSection({onClick}) {

    const words = [
        "BFF",
        "Partner",
        "Buddy",
        "Mentor"
    ];


    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index == words.length)  {
            setIndex(0);
        } else {
            setTimeout(() =>  {
                setIndex(index+1);
            }, 1000);
        }
    }, [index]);


    return (
        <div className='home--firstTitle--wrapper'>
            <div className='home--title--wrapper'>
                <div className='home--title'>Meet yout next {words[index]}</div>
            </div>
            <div className='home--subtitle--wrapper'>
                <div className='home--subtitle'>No more chit chat. </div>
                <div className='home--subtitle--second'>Build Relationships IRL.</div>
            </div>
            <div className='home--app--wrapper' onClick={onClick}>
                <img className='home--google' src={GoogleStore}/>
                <img className='home--apple' src={AppleStore}/>
            </div>
        </div>
    );
}

export default FirstSection;