import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/logo.png';
import './Navbar.css';

function MyNavbar() {
    return (
        <Navbar expand="lg" bg="primary" variant='dark'>
            <Container>
                <Navbar.Brand href="/" className='text-light fw-bold'>
                    <img src={logo}/>
                    Skip the Chit Chat
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                    <Nav.Link href="/" className='text-light'>Home</Nav.Link>
                    <Nav.Link href="/about" className='text-light'>About</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Link href="/log-in" className='text-light'>Log In</Nav.Link>
                    <Nav.Link href="/sign-up" className='text-light'>Sign Up</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MyNavbar;