import React from 'react';

import emailImage from '../images/dizzy-relaxed-time.png';
import './EmailOverlay.css';

export function EmailOverlay({isOpen, onClose, children}) {
    return (
        <>
            {
                isOpen ? (
                    <div className='email--overlay'>
                        <div className='email--overlay--background' onClick={onClose}/>
                        <div className='email--overlay--container'>
                            <img src={emailImage}/>
                            <div className='email--overlay--text'>
                                {children}
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
};