import React from 'react';

function ForthSection() {
    return (
        <div className='home--second--text--wrapper'>
            <div className='home--second--title'>
            <b>Vancouver</b> is a lonely city.
            </div>
            <div className='home--second--subtitle'>
            Our mission is to connect people through shared interests and activities. Whether you want to stroll along the seawall or finding a study buddy, it's all about doing what you love, with people who love it too.
            </div>
        </div>
    );
}

export default ForthSection;