import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
/* The following line can be included in your src/index.js or App.js file */
// import './App.scss';
import './custom.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
