import React from 'react';

function FifthSection() {
    return (
        <div className='home--third--first--section'>
            Skip the chit chat
        </div>
    );
}

export default FifthSection;