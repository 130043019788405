import React from 'react'

function About() {
    return (
        <div>
            <h3>About</h3>
        </div>
    );
};

export default About;