import React from "react";
import './Status.css';
import userIcon from '../images/Users Icon.png';

function Status({statusTitle, statusStatus}) {
    return (
        <div className="status--container">
            <div className="status--title">
                {statusTitle}
            </div>
            <div className="status--status">
                <text>{statusStatus}</text>
                <img src={userIcon}/>
            </div>
        </div>
    )
}

export default Status;