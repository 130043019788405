import React, {useState} from 'react';
import './Home.css';
import MyMap from '../components/Map';
import FirstSection from './Home/FirstSection';
import SecondSection from './Home/SecondSection';
import ForthSection from './Home/ForthSection';
import FifthSection from './Home/FifthSection';
import SixSection from './Home/SixSection';

import { EmailOverlay } from '../components/EmailOverlay';

function Home() {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const onSubmit = function(e)  {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ title: 'React Hooks POST Request Example' })
        };
        fetch('https://skipthechitchat.ca:3000/addEmailToSubscriptionEmailList', {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            method: 'POST',
            body: JSON.stringify({email: "tzhang995@gmail.com"})
        }).then(response => {
            console.log(response.text);
        });
    }
    return (
        <div className='home--container'>
            <EmailOverlay isOpen={isOverlayOpen} onClose={() => setIsOverlayOpen(!isOverlayOpen)}>
                <div className='email--overlay--title'>
                    Waitlist.
                </div>
                <div className='email--overlay--subtitle'>
                    Can’t wait to meet new people by doing the things you love? Join our waitlist and be the first to join.
                </div>
                <form className='email--overlay--form'
                    onSubmit={onSubmit}>
                    <input type="text" placeholder='email@provider.com'/>
                    <button type ='submit'>Subscribe</button>
                </form>
            </EmailOverlay>
            <div className='home--first--section'>
                <FirstSection onClick={() => setIsOverlayOpen(!isOverlayOpen)}/>
                <SecondSection/>
            </div>
            <div className='home--second--section'>
                <MyMap/>
                <ForthSection/>
            </div>
            <div className='home--third--section'>
                <FifthSection/>
                <SixSection onClick={() => setIsOverlayOpen(!isOverlayOpen)}/>
            </div>
        </div>
    );
};

export default Home;